import React, { useEffect } from 'react';
import './App.css';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'; // Importar ScrollToPlugin

import logo from './assets/logo/FRcustom_white3.png';
import videoMobile from './assets/pedida_compressed/PROPOSAL_Centrao.mp4'; // Video para móvil
import videoDesktop from './assets/pedida/proposal_video.mp4'; // Nuevo video para escritorio
import photo5 from './assets/pedida_compressed/Raul&amp_Fer174-black-white.webp';
import photo4 from './assets/pedida_compressed/Raul&amp_Fer117.webp';
import photo3 from './assets/pedida_compressed/Raul&amp_Fer071.webp';
import photo2 from './assets/pedida_compressed/Raul&amp_Fer075.webp';
import photo1 from './assets/pedida_compressed/Raul&amp_Fer146.webp';

import trajeVestidoSVG from './assets/svg/clothes.svg'; // Importar 1.svg
import floresSVG from './assets/svg/11.svg'; // Importar 2.svg

gsap.registerPlugin(ScrollToPlugin); // Registrar el plugin

function App() {
  const scrollToSection = (id) => {
    gsap.to(window, { duration: 1.5, scrollTo: { y: id, offsetY: 60, autoKill: false }, ease: "power2.inOut" }); // Ajustar el desplazamiento
  };

  useEffect(() => {
    const handleScroll = () => {
      const heroHeight = document.querySelector('.hero').offsetHeight;
      const scrollPosition = window.scrollY;

      if (scrollPosition > heroHeight * 0.2) {
        scrollToSection('#location');
        window.removeEventListener('scroll', handleScroll); // Remover el evento después de hacer scroll
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <>
        {/* Hero Section */}
        <section className="hero">
          {/* Video para móvil */}
          <video className="hero-video mobile-video" src={videoMobile} autoPlay loop muted playsInline></video>
          {/* Video para escritorio */}
          <video className="hero-video desktop-video" src={videoDesktop} autoPlay loop muted playsInline></video>
          <div className="hero-content">
            <img className="logo" src={logo} alt="F & R Monogram" />
            <h1>Fernanda & Raúl</h1>
            <p className="event-date">8 de febrero de 2025</p>
          </div>
          <div className='under-hero-row'>
            <button className="language-button">EN | ES</button>
          </div>
        </section>

        {/* Navbar Section */}
        <nav className="navbar">
          <div className="navbar-container">
            <ul className="navbar-scrollable">
              <li><a href="#location" onClick={(e) => { e.preventDefault(); scrollToSection('#location'); }}>Ubicación</a></li>
              <li><a href="#itinerary" onClick={(e) => { e.preventDefault(); scrollToSection('#itinerary'); }}>Itinerario</a></li>
              <li><a href="#dresscode" onClick={(e) => { e.preventDefault(); scrollToSection('#dresscode'); }}>Código de Vestimenta</a></li>
              <li><a href="#gifts" onClick={(e) => { e.preventDefault(); scrollToSection('#gifts'); }}>Mesa Regalos</a></li>
              <li><a href="#gifts" onClick={(e) => { e.preventDefault(); scrollToSection('#rsvp'); }}>RSVP</a></li>
            </ul>
          </div>
        </nav>

        {/* Location Section */}
        <section id="location" className="location">
          <h2>Ubicación</h2>
          <p>Jardín Rincón Dorado, Chiconcuac, Morelos</p>
          <p>Será un honor compartir este día tan especial contigo en este hermoso lugar.</p>
          <a href="https://www.google.com/maps?rlz=1C1GCEA_enMX1112MX1112&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyEAgBEC4YrwEYxwEYgAQYjgUyBggCEEUYOTIICAMQABgWGB4yCAgEEAAYFhgeMggIBRAAGBYYHjIICAYQABgWGB4yCAgHEAAYFhgeMggICBAAGBYYHjIICAkQABgWGB7SAQgzMjMxajBqN6gCALACAA&um=1&ie=UTF-8&fb=1&gl=mx&sa=X&geocode=Ke25p0kS2M2FMeTHU2uRCNsl&daddr=Zapata+-+Zacatepec+(Av.+Emiliano+Zapata),+El+Pedregal,+62790+Chiconcuac,+Mor." className="location-button">Ver Ubicación</a>
        </section>

        {/* Photo Divider */}
        <div className="photo-divider" style={{ backgroundImage: `url(${photo1})` }}>
        </div>

        {/* Itinerary Section */}
        <section id="itinerary" className="itinerary">
          <h2>Itinerario</h2>
          <div className="stepper">
            <div className="step right">
              <div className="step-content">
                <div className="step-time">1:30 PM</div>
                <div className="step-description">Recepción</div>
              </div>
            </div>
            <div className="step left">
              <div className="step-content">
                <div className="step-time">2:00 PM</div>
                <div className="step-description">Ceremonia</div>
              </div>
            </div>
            <div className="step right">
              <div className="step-content">
                <div className="step-time">3:00 PM</div>
                <div className="step-description">Coctel</div>
              </div>
            </div>
            <div className="step left">
              <div className="step-content">
                <div className="step-time">4:40 PM</div>
                <div className="step-description">Banquete</div>
              </div>
            </div>
            <div className="step right">
              <div className="step-content">
                <div className="step-time">6:00 PM</div>
                <div className="step-description">Apertura de Pista</div>
              </div>
            </div>
            <div className="step left">
              <div className="step-content">
                <div className="step-time">12:30 AM</div>
                <div className="step-description">Tornaboda</div>
              </div>
            </div>
            <div className="step right">
              <div className="step-content">
                <div className="step-time">2:00 AM</div>
                <div className="step-description">Fin del festejo</div>
              </div>
            </div>
          </div>
        </section>

        {/* Photo Divider */}
        <div className="photo-divider" style={{ backgroundImage: `url(${photo2})` }}>
        </div>

        {/* Dress Code Section */}
        <section id="dresscode" className="dresscode">
          <h2>Código de Vestimenta</h2>
          <p>Código de vestimenta: Formal riguroso. Agradecemos evitar el uso de colores blancos o similares.</p>
          <div className="dresscode-images">
            <img src={trajeVestidoSVG} alt="Traje y Vestido" />
          </div>
        </section>

        {/* Photo Divider */}
        <div className="photo-divider" style={{ backgroundImage: `url(${photo3})` }}>
        </div>

        {/* Gifts Section */}
        <section id="gifts" className="gifts">
          <h2>Mesa de Regalos</h2>
          <p>Tu presencia es el mejor regalo, pero si lo deseas, apreciamos tu gentil obsequio de nuestra mesa de regalos o una contribución monetaria.</p>
          <a href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51543584" rel="noopener noreferrer" target='_blank' className="location-button">Ver Mesa de Regalos</a>

          <p><strong>Entidad:</strong> NU MEXICO</p>
          <p><strong>Número CLABE:</strong> 638180000110367297</p>
          <p><strong>Número de cuenta:</strong> 00011036729</p>
          <p><strong>Nombre:</strong> Maria Fernanda Tejeda Serna</p>
        </section>

        {/* Photo Divider */}
        <div className="photo-divider" style={{ backgroundImage: `url(${photo4})` }}>
        </div>

        {/* Lista de Hoteles Cercanos */}
        <section id="hotels" className="hotels">
          <h2>Lista de Hoteles Cercanos</h2>
          <p>Puedes encontrar una lista de hoteles cercanos haciendo clic en el siguiente enlace:</p>
          <a href="https://drive.google.com/file/d/10VWIg3L8IPOPWiI3LSJHQ2y5faSo85iP/view?usp=sharing" className="location-button" target="_blank" rel="noopener noreferrer">Ver Hoteles Cercanos</a>
        </section>

        {/* Photo Divider */}
        <div className="photo-divider" style={{ backgroundImage: `url(${photo5})` }}>
        </div>

        {/* RSVP Section */}
        <section id="rsvp" className="rsvp">
          <h2>Confirmación de Asistencia</h2>
          <p>Nos encantaría contar contigo en nuestro día especial. La confirmación de asistencia será realizada a mediados de diciembre a través de llamada o WhatsApp. Por favor, mantente pendiente.</p>
          <p>Para confirmar o cualquier duda se pueden comunicar al siguiente número:</p>
          {/* callto +52 1 777 119 2331 */}
          <a href="tel:+527771192331" className="location-button">+52 1 777 119 2331</a>
          <p className="guest-note">Queremos que disfruten este día con nosotros, por lo que la celebración será solo para adultos.</p>
        </section>

        {/* Flower SVGs */}
        <img src={floresSVG} className="flower-left" alt="Flores" />
        <img src={floresSVG} className="flower-right" alt="Flores" />


        {/* Footer */}
        <footer> 2025 Raúl & Fernanda
        </footer>
      </>
    </div>
  );
}
export default App;
